<template>
  <v-row style="max-width: 1100px" class="mx-auto pb-16 my-16">
    <v-col>
      <v-img
          max-width="500px"
          src="@/assets/financing.jpg"
          contain
          rounded
          class="rounded mx-8"
      >
      </v-img>
    </v-col>
    <v-col class="pt-16 mt-16">
      <p class="mb-0 font-weight-bold" style="color: #70BAFF">{{$t('prices.payout_title')}}</p>
      <h2>{{$t('prices.payout_header')}}</h2>
      <p>{{$t('prices.payout_text')}}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FastPayout"
}
</script>

<style scoped>

</style>